$color-white: #ffffff;
$color-green: #006233;
$color-silver-sand: #BABBBC;
$color-sky-blue: #20AAE4;
$color-loght-sky-blue: #EEF6FF;

// color
$primary-color: $color-sky-blue;
$header-bg-color: $color-white;
$body-bg-color: $color-loght-sky-blue;
$primary-button-color: $color-sky-blue;


// font
$primary-font-family: 'Inter';
$font-size-title: 30px;
$font-size-title-md: 25px;