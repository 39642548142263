@import "./elements";
body {
  font-size: 14px;
  background-color: $body-bg-color;
  font-family: $primary-font-family;
}

.p-1 { padding: 4px}
.p-2 { padding: 8px}
.p-4 { padding: 16px}
.p-8 { padding: 32px}
.p-12 { padding: 48px}
.p-16 { padding: 64px}

.mb-1 { margin-bottom: 4px}
.mb-2 { margin-bottom: 8px}
.mb-4 { margin-bottom: 16px}
.mb-8 { margin-bottom: 32px}
.mb-16 { margin-bottom: 64px}

.mt-1 { margin-top: 4px}
.mt-2 { margin-top: 8px}
.mt-4 { margin-top: 16px}
.mt-8 { margin-top: 32px}
.mt-16 { margin-top: 64px}

.mlp-25 { margin-left: 25% }

.title-text {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-title;
}
.title-text-md {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-title-md;
}

.ant-btn-primary {
  background: $primary-button-color;
  border-radius: 0px 4px 4px 0px;
}

.ant-btn-lg {  height: 55px; }

.ant-btn-primary:hover, .ant-btn-primary:focus {
    border-color: $primary-button-color;
    background:  $primary-button-color;
}

.ant-input-affix-wrapper > input.ant-input {
  height: 40px;
  padding: 20px 15px;
  border-radius: 7px;

}

.ant-btn-link {
  color: $primary-color;
}

.ant-input-affix-wrapper {
  height: 50px;
  border-radius: 7px;
}

.ant-input {
  height: 50px;
  padding: 20px 25px;
  border-radius: 7px;
}
.ant-avatar {
  background-color: #ccc0;
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
}
