.footer {
  background-color: #f0f2f5 !important;
  z-index: 1;
  &.floating-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
